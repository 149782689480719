/* modify Bootstrap variables here */

@import 'bootstrap/bootstrap';
$bg-color: #034221;

$hover-color: #2bab48;

/* add additional CSS rules below */

.navbar {
    background-color: $bg-color;
    margin-bottom: 2em;
}

body {
	font-size: 1.25rem;
	font-weight: 300;
}

.btn {
	background-color: $bg-color;
	padding: 12px 30px;
	border: none;
}
.btn:hover {
	background-color: $hover-color;
}

.btn:active {
	background-color: $hover-color;
}

#recipedownload {
	align: right;
	@extend .btn;
	@extend .btn-primary;
}

footer {
    width: 100%;
    line-height: 60px;
    background-color: $bg-color;
    margin-top: 2em;
}

h1 {
    color: $bg-color;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.05rem;
}